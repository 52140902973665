$mainColor: #000;
$secondColor: #E2462C;
$thirdColor: #4D4D4D;
$fourthColor: #FFF;
$fifthColor: #000;
$sixthColor: #DA9500;
$kolegiaBlue: #4861A4;

$greenColor: #43D551;
$greenColorTwo: #1C7300;
$yellowColor: #F1F100;
$orangeColor: #FFA500;
$redColor: #FF3333;
$blueColor: #113FFF;

$grayBackground: #F7F7F7;
$grayColorOne: #F1F1F1;
$grayColorTwo: #E9E9E9;
$grayColorThree: #DDD;
$grayColorFour: #CCCCD0;
$grayColorFive: #A8A8A8;

$backGround: rgba(255, 255, 255, 0.97);
$oxford1Light: #263238;
$oxfordDark: #1E282D;