@charset "UTF-8";
@import '../variables/variables_liceo.scss';



//html[data-theme='dark'] {
//  filter: invert(1) hue-rotate(180deg);
//}
//
//html[data-theme='dark'] img {
//  filter: invert(1) hue-rotate(180deg);
//}

@font-face {
  font-family: 'ProductSans Regular';
  src: url('../font/Product Sans Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ProductSans Regular';
  font-style: italic;
  src: url('../font/Product Sans Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ProductSans Bold';
  font-weight: bold;
  src: url('../font/Product Sans Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ProductSans Bold';
  font-weight: bold;
  font-style: italic;
  src: url('../font/Product Sans Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SF-regular';
  src: url('../font/SFRegular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SF-bold';
  src: url('../font/SFBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

div::-webkit-scrollbar-track {
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.noScroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

div.grid {
  margin: 0;
  padding: 0;
}

.uk-grid-margin {
  margin: 0 !important;
}

div.row {
  margin: 0;
  padding: 0;
}

div.col {
  margin: 0;
  padding: 0;
}

div.public-DraftStyleDefault-block {
  margin: .6px;
}

option {
  text-align: left;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
}

p {
  margin: 10px 0;
  width: 100%;
}

.thin_scroll {
  scrollbar-width: thin; // auto | thin | none | <length>;
}

// Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.separator {
  height: 200px
}

.separator-small {
  height: 100px
}

.ribbon-wrapper-red {
  width: 42.5px;
  height: 44px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}

.ribbon-red {
  font: bold 7px Sans-Serif;
  color: #fff;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 3px 0;
  left: -3px;
  top: 7px;
  width: 60px;
  background-color: red;
}


.btn-grad {
  background: linear-gradient(120deg, $thirdColor 30%, $kolegiaBlue 100%)
}

.btn-grad {
  transition: 0.5s;
  background-size: 200% auto;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-text-grad {
  color: $fourthColor
}

//.btn-normal {
//  background: linear-gradient(120deg, $grayColorTwo, $grayColorOne, $thirdColor, $kolegiaBlue);
//}
//
//.btn-normal {
//  transition: 0.7s;
//  background-size: 400% auto;
//}

//.btn-normal:hover {
//  background-position: right center;
//  color: $fourthColor;
//  text-decoration: none;
//}

.btn-text-normal {
  color: $fifthColor
}

.btn-normal:hover .btn-text-normal {
  color: $fourthColor;
}

.imageDash {
  width: 150px !important;
  height: 150px !important;
}

.dashboard-left {
  max-height: calc(100vh - 20px)
}

.dashboard-image {
  height: calc(18% - 20px)
}

.dashboard-menu {
  height: calc(80% - 40px);
}

.dashboard-center {
  max-height: calc(100vh - 20px)
}

.dashboard-center-column {
  max-height: calc(100vh - 10px)
}

.dashboard-middle {
  max-height: calc(100% - 20px)
}

.dashboard-middle-box {
  max-height: calc(100% - 25px)
}


@media only screen and (max-height: 500px) {
  .dashboard-image {
    height: calc(23% - 20px)
  }

  .dashboard-menu {
    height: calc(75% - 20px)
  }
}

@media only screen and (max-height: 450px) {
  .dashboard-image {
    height: calc(28% - 20px)
  }

  .dashboard-menu {
    height: calc(70% - 20px)
  }
}

.spinner {
  padding: 3px;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: $secondColor;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.avatar {
  vertical-align: middle;
  width: 7vw;
  height: 7vw;
  border-radius: 50%;
}

.container_grab {
  cursor: grab;
  overflow: auto;
}

.Image-container {
  overflow: hidden;
}

.Image-image {
  min-width: 80%;
  max-width: 80%;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.blob {
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 0 0 red;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F1F1F1;
  background: linear-gradient(to right, #F1F1F1 8%, #DDD 18%, #F1F1F1 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.animated {
  @extend .animated-background;
}

.animated-normal-text {
  height: 10px;
  @extend .animated-background;
}

.animated-sub-title-text {
  height: 15px;
  @extend .animated-background;
}


.scrollG {
  float: left;
  border-color: white;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.scrollA {
  float: left;
  border-color: white;
  max-height: calc(100vh - 130px);
  overflow: auto;
}

div::-webkit-scrollbar {
  //position: relative;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

div:hover::-webkit-scrollbar {
  //width: 6px;
  //position: relative;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
}

.scroll-chat {
  flex-direction: column-reverse;
  float: left;
  overflow: auto;
  border-color: white;
}

.scroll-chat::-webkit-scrollbar {
  width: 10px;
}

.scroll-chat::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scroll-chat::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

@-webkit-keyframes opacityPulse {
  0% {
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.0;
  }
}


.own-modal {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.own-modal-content {
  background-color: #fefefe;
  position: relative;
  margin: 5% auto;
  padding: 15px 15px 15px 15px;
  width: 80%;
  max-height: 80%;
  overflow: auto;
  border-radius: 15px;
}

.full_modal {
  background-color: #fefefe;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
  border-radius: 0;
}

.x_small-modal {
  width: 30%;
}

.xx_small-modal {
  width: 25%;
}

.small-modal {
  width: 40%;
}

.medium-modal {
  width: 60%;
}

.close-modal {
  color: #aaa;
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 30px;
  font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.w-1 {
  width: 100%;
}

.w-1-2 {
  width: 50%;
}

.w-1-3 {
  width: calc(100% * 1 / 3.001);
}

.w-1-3 {
  width: calc(100% * 2 / 3.001);
}

.w-1-4 {
  width: 25%;
}

.w-3-4 {
  width: 75%;
}

.w-1-5 {
  width: 20%;
}

.w-2-5 {
  width: 40%;
}

.w-3-5 {
  width: 60%;
}

.w-4-5 {
  width: 80%;
}

.w-1-6 {
  width: calc(100% * 1 / 6.001);
}

.w-5-6 {
  width: calc(100% * 5 / 6.001);
}

.w-1-7 {
  width: calc(100% * 1 / 7.001);
}

.w-2-7 {
  width: calc(100% * 2 / 7.001);
}

.w-3-7 {
  width: calc(100% * 3 / 7.001);
}

.w-4-7 {
  width: calc(100% * 4 / 7.001);
}

.w-5-7 {
  width: calc(100% * 5 / 7.001);
}

.w-6-7 {
  width: calc(100% * 6 / 7.001);
}

.w-1-8 {
  width: calc(100% * 1 / 8.001);
}

.w-3-8 {
  width: calc(100% * 3 / 8.001);
}

.w-5-8 {
  width: calc(100% * 5 / 8.001);
}

.w-7-8 {
  width: calc(100% * 7 / 8.001);
}

.w-1-9 {
  width: calc(100% * 1 / 9.001);
}

.w-2-9 {
  width: calc(100% * 2 / 9.001);
}

.w-4-9 {
  width: calc(100% * 4 / 9.001);
}

.w-5-9 {
  width: calc(100% * 5 / 9.001);
}

.w-7-9 {
  width: calc(100% * 7 / 9.001);
}

.w-8-9 {
  width: calc(100% * 8 / 9.001);
}

.w-1-10 {
  width: calc(100% * 1 / 10.001);
}

.w-3-10 {
  width: calc(100% * 3 / 10.001);
}

.w-7-10 {
  width: calc(100% * 7 / 10.001);
}

.w-9-10 {
  width: calc(100% * 9 / 10.001);
}

@media (max-width: 960px) {
  .avatar {
    width: 13vw;
    height: 13vw;
  }
}

@media (max-width: 640px) {
  .avatar {
    width: 25vw;
    height: 25vw;
  }
}

@media (min-width: 1600px) {
  .wxl-1 {
    width: 100%;
  }
  .wxl-1-2 {
    width: 50%;
  }
  .wxl-1-3 {
    width: calc(100% * 1 / 3.001);
  }
  .wxl-1-3 {
    width: calc(100% * 2 / 3.001);
  }
  .wxl-1-4 {
    width: 25%;
  }
  .wxl-3-4 {
    width: 75%;
  }
  .wxl-1-5 {
    width: 20%;
  }
  .wxl-2-5 {
    width: 40%;
  }
  .wxl-3-5 {
    width: 60%;
  }
  .wxl-4-5 {
    width: 80%;
  }
  .wxl-1-6 {
    width: calc(100% * 1 / 6.001);
  }
  .wxl-5-6 {
    width: calc(100% * 5 / 6.001);
  }
  .wxl-1-7 {
    width: calc(100% * 1 / 7.001);
  }
  .wxl-2-7 {
    width: calc(100% * 2 / 7.001);
  }
  .wxl-3-7 {
    width: calc(100% * 3 / 7.001);
  }
  .wxl-4-7 {
    width: calc(100% * 4 / 7.001);
  }
  .wxl-5-7 {
    width: calc(100% * 5 / 7.001);
  }
  .wxl-6-7 {
    width: calc(100% * 6 / 7.001);
  }
  .wxl-1-8 {
    width: calc(100% * 1 / 8.001);
  }
  .wxl-3-8 {
    width: calc(100% * 3 / 8.001);
  }
  .wxl-5-8 {
    width: calc(100% * 5 / 8.001);
  }
  .wxl-7-8 {
    width: calc(100% * 7 / 8.001);
  }
  .wxl-1-9 {
    width: calc(100% * 1 / 9.001);
  }
  .wxl-2-9 {
    width: calc(100% * 2 / 9.001);
  }
  .wxl-4-9 {
    width: calc(100% * 4 / 9.001);
  }
  .wxl-5-9 {
    width: calc(100% * 5 / 9.001);
  }
  .wxl-7-9 {
    width: calc(100% * 7 / 9.001);
  }
  .wxl-8-9 {
    width: calc(100% * 8 / 9.001);
  }
  .wxl-1-10 {
    width: calc(100% * 1 / 10.001);
  }
  .wxl-3-10 {
    width: calc(100% * 3 / 10.001);
  }
  .wxl-7-10 {
    width: calc(100% * 7 / 10.001);
  }
  .wxl-9-10 {
    width: calc(100% * 9 / 10.001);
  }

  .xx_small-modal {
    width: 28%;
  }
}

@media (min-width: 1200px) {
  .wl-1 {
    width: 100%;
  }
  .wl-1-2 {
    width: 50%;
  }
  .wl-1-3 {
    width: calc(100% * 1 / 3.001);
  }
  .wl-1-3 {
    width: calc(100% * 2 / 3.001);
  }
  .wl-1-4 {
    width: 25%;
  }
  .wl-3-4 {
    width: 75%;
  }
  .wl-1-5 {
    width: 20%;
  }
  .wl-2-5 {
    width: 40%;
  }
  .wl-3-5 {
    width: 60%;
  }
  .wl-4-5 {
    width: 80%;
  }
  .wl-1-6 {
    width: calc(100% * 1 / 6.001);
  }
  .wl-5-6 {
    width: calc(100% * 5 / 6.001);
  }
  .wl-1-7 {
    width: calc(100% * 1 / 7.001);
  }
  .wl-2-7 {
    width: calc(100% * 2 / 7.001);
  }
  .wl-3-7 {
    width: calc(100% * 3 / 7.001);
  }
  .wl-4-7 {
    width: calc(100% * 4 / 7.001);
  }
  .wl-5-7 {
    width: calc(100% * 5 / 7.001);
  }
  .wl-6-7 {
    width: calc(100% * 6 / 7.001);
  }
  .wl-1-8 {
    width: calc(100% * 1 / 8.001);
  }
  .wl-3-8 {
    width: calc(100% * 3 / 8.001);
  }
  .wl-5-8 {
    width: calc(100% * 5 / 8.001);
  }
  .wl-7-8 {
    width: calc(100% * 7 / 8.001);
  }
  .wl-1-9 {
    width: calc(100% * 1 / 9.001);
  }
  .wl-2-9 {
    width: calc(100% * 2 / 9.001);
  }
  .wl-4-9 {
    width: calc(100% * 4 / 9.001);
  }
  .wl-5-9 {
    width: calc(100% * 5 / 9.001);
  }
  .wl-7-9 {
    width: calc(100% * 7 / 9.001);
  }
  .wl-8-9 {
    width: calc(100% * 8 / 9.001);
  }
  .wl-1-10 {
    width: calc(100% * 1 / 10.001);
  }
  .wl-3-10 {
    width: calc(100% * 3 / 10.001);
  }
  .wl-7-10 {
    width: calc(100% * 7 / 10.001);
  }
  .wl-9-10 {
    width: calc(100% * 9 / 10.001);
  }
  .xx_small-modal {
    width: 32%;
  }
}

@media (min-width: 960px) {
  .wm-1 {
    width: 100%;
  }
  .wm-1-2 {
    width: 50%;
  }
  .wm-1-3 {
    width: calc(100% * 1 / 3.001);
  }
  .wm-1-3 {
    width: calc(100% * 2 / 3.001);
  }
  .wm-1-4 {
    width: 25%;
  }
  .wm-3-4 {
    width: 75%;
  }
  .wm-1-5 {
    width: 20%;
  }
  .wm-2-5 {
    width: 40%;
  }
  .wm-3-5 {
    width: 60%;
  }
  .wm-4-5 {
    width: 80%;
  }
  .wm-1-6 {
    width: calc(100% * 1 / 6.001);
  }
  .wm-5-6 {
    width: calc(100% * 5 / 6.001);
  }
  .wm-1-7 {
    width: calc(100% * 1 / 7.001);
  }
  .wm-2-7 {
    width: calc(100% * 2 / 7.001);
  }
  .wm-3-7 {
    width: calc(100% * 3 / 7.001);
  }
  .wm-4-7 {
    width: calc(100% * 4 / 7.001);
  }
  .wm-5-7 {
    width: calc(100% * 5 / 7.001);
  }
  .wm-6-7 {
    width: calc(100% * 6 / 7.001);
  }
  .wm-1-8 {
    width: calc(100% * 1 / 8.001);
  }
  .wm-3-8 {
    width: calc(100% * 3 / 8.001);
  }
  .wm-5-8 {
    width: calc(100% * 5 / 8.001);
  }
  .wm-7-8 {
    width: calc(100% * 7 / 8.001);
  }
  .wm-1-9 {
    width: calc(100% * 1 / 9.001);
  }
  .wm-2-9 {
    width: calc(100% * 2 / 9.001);
  }
  .wm-4-9 {
    width: calc(100% * 4 / 9.001);
  }
  .wm-5-9 {
    width: calc(100% * 5 / 9.001);
  }
  .wm-7-9 {
    width: calc(100% * 7 / 9.001);
  }
  .wm-8-9 {
    width: calc(100% * 8 / 9.001);
  }
  .wm-1-10 {
    width: calc(100% * 1 / 10.001);
  }
  .wm-3-10 {
    width: calc(100% * 3 / 10.001);
  }
  .wm-7-10 {
    width: calc(100% * 7 / 10.001);
  }
  .wm-9-10 {
    width: calc(100% * 9 / 10.001);
  }
  .xx_small-modal {
    width: 37%;
  }
}

@media (min-width: 640px) {
  .ws-1 {
    width: 100%;
  }
  .ws-1-2 {
    width: 50%;
  }
  .ws-1-3 {
    width: calc(100% * 1 / 3.001);
  }
  .ws-1-3 {
    width: calc(100% * 2 / 3.001);
  }
  .ws-1-4 {
    width: 25%;
  }
  .ws-3-4 {
    width: 75%;
  }
  .ws-1-5 {
    width: 20%;
  }
  .ws-2-5 {
    width: 40%;
  }
  .ws-3-5 {
    width: 60%;
  }
  .ws-4-5 {
    width: 80%;
  }
  .ws-1-6 {
    width: calc(100% * 1 / 6.001);
  }
  .ws-5-6 {
    width: calc(100% * 5 / 6.001);
  }
  .ws-1-7 {
    width: calc(100% * 1 / 7.001);
  }
  .ws-2-7 {
    width: calc(100% * 2 / 7.001);
  }
  .ws-3-7 {
    width: calc(100% * 3 / 7.001);
  }
  .ws-4-7 {
    width: calc(100% * 4 / 7.001);
  }
  .ws-5-7 {
    width: calc(100% * 5 / 7.001);
  }
  .ws-6-7 {
    width: calc(100% * 6 / 7.001);
  }
  .ws-1-8 {
    width: calc(100% * 1 / 8.001);
  }
  .ws-3-8 {
    width: calc(100% * 3 / 8.001);
  }
  .ws-5-8 {
    width: calc(100% * 5 / 8.001);
  }
  .ws-7-8 {
    width: calc(100% * 7 / 8.001);
  }
  .ws-1-9 {
    width: calc(100% * 1 / 9.001);
  }
  .ws-2-9 {
    width: calc(100% * 2 / 9.001);
  }
  .ws-4-9 {
    width: calc(100% * 4 / 9.001);
  }
  .ws-5-9 {
    width: calc(100% * 5 / 9.001);
  }
  .ws-7-9 {
    width: calc(100% * 7 / 9.001);
  }
  .ws-8-9 {
    width: calc(100% * 8 / 9.001);
  }
  .ws-1-10 {
    width: calc(100% * 1 / 10.001);
  }
  .ws-3-10 {
    width: calc(100% * 3 / 10.001);
  }
  .ws-7-10 {
    width: calc(100% * 7 / 10.001);
  }
  .ws-9-10 {
    width: calc(100% * 9 / 10.001);
  }
}


body {
  font-family: ProductSans Regular, sans-serif;
}

*:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}

.react-confirm-alert-svg {
  display: none !important;
}

#content {
  width: 25vw;
  margin: 0 auto;
  border-radius: 6px !important;
}

.dayView {
  width: 3.1vw !important;
}

.truncate {
  //white-space: nowrap;
  //overflow: hidden;
  text-overflow: ellipsis;
}

.nmp {
  margin: 0;
  padding: 0;
}

//dd
.module_title {
  align-items: flex-start;
  color: $fifthColor;
  font-family: 'work Sans', sans-serif;
  font-size: 30px;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.modal_title {
  align-items: flex-start;
  color: $fifthColor;
  font-family: 'work Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.normal_text2 {
  align-items: flex-start;
  color: $fifthColor;
  font-family: 'work Sans', sans-serif;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

// main_title
.sub_title {
  align-items: flex-start;
  color: $thirdColor;
  font-family: 'SF-bold', sans-serif;
  font-size: 16px;
  text-align: left;
  margin: 0;
  padding: 0;
}

// txtnegritasMini
.section_title {
  align-items: center;
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
}

// txtnegritasMiniBlack
.section_title_two {
  align-items: center;
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
}

// work
// before in font-family   font-family: sans-serif;
.normal_text {
  color: $fifthColor;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.Color1 {
  color: $mainColor;
}

.Color2 {
  color: $secondColor;
}

.Color3 {
  color: $thirdColor;
}

.Color4 {
  color: $fourthColor;
}

.a {
  margin-top: 7px;
  width: 80px;
}

.accor {
  width: 88%;
}

.altoheader {
  background-color: $fourthColor;
  text-align: center;
  width: 100%;
}

.borderColor {
  border: 1px solid;
  border-color: $fifthColor !important;
}

.borderWidth {
  border: 1px solid $mainColor !important;
  height: 80px;
}

.botontxt {
  align-items: center;
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
}

.btn2 {
  width: 60vh;
}

.btn3 {
  border-radius: 4px;
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  height: 20px;
  text-decoration: none;
  padding: 0 5px;
}

.btn4 {
  background-color: red;
  border-radius: 10px;
  height: 3vh;
  width: 21vh;
}

.btnEditor {
  color: $fifthColor;
  border: 1px solid $grayColorTwo;
  border-radius: 6px;
  height: 3vh;
  width: 3vw;
  margin: .5px;
}

.btncer3 {
  border-radius: 3px;
  height: 20px;
}

.btnnn {
  border-radius: 4px;
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  height: 20px;
  text-decoration: none;
  padding: 0 20px;
}

.btnpara2 {
  box-shadow: 0 0 0;
  text-transform: capitalize;
}

.callto40 {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.81);
  justify-content: center;
  text-align: center;
}

.callto404 {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.81);
  justify-content: center;
  text-align: center;
  width: 61%;
}

.calltoAction {
  background-color: $secondColor;
  text-align: center;
  width: 100%;
}

.calltotext {
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
}

.centerAll {
  align-items: center;
  justify-content: center;
}

.centerH {
  justify-content: center;
}

.centerV {
  align-items: center;
}

.container, .full {
  height: 100vh;
}

.cuadro {
  background-color: $grayColorOne;
  border-radius: 10px;
  //flex-basis: 45% !important;
  height: 170px;
  padding: 30px 5px 5px 5px;
  margin: 0;
}

.cuadroCard {
  border-radius: 12px;
  height: 450px;
  width: 350px;
  margin: 5px;
}

.cuadroList {
  align-items: center;
  justify-content: center;
  width: 22vh;
}

.cuadroLogin {
  align-items: center;
  justify-content: center;
  max-width: 10vw;
  max-height: 20vh;
}

.modalAdmisionWidth {
  width: 25vw;
}

.customalertWidth {
  width: 30vw;
  padding: 40px
}

.widthCardDoc {
  width: 50%;
}

.heightCardAsp {
  height: 56vh;
}

.cuadroDocto {
  height: 16vh;
}

.heightPreinscrip {
  height: 90.5vh;
}

.d {
  height: 30vh;
  width: 70vw;
}

.d1 {
  align-items: flex-start;
  color: $fifthColor;
  font-family: 'work Sans', sans-serif;
  font-size: 21px;
  font-weight: 800;
}

.dd {
  align-items: flex-start;
  color: $fifthColor;
  font-family: 'work Sans', sans-serif;
  font-size: 30px;
  line-height: 28px;
  margin-bottom: 10px;
  font-weight: 800;
}

.dd3 {
  align-items: flex-start;
  color: $fifthColor;
  font-family: 'work Sans', sans-serif;
  font-size: 15px;
  font-weight: 800;
}

.di {
  width: 165px;
}

.encabezado {
  align-items: flex-start;
  color: $oxfordDark;
  font-family: 'Work Sans', sans-serif;
  font-size: 45px;
  font-weight: 600;
  text-align: center;
}

.encabezadoDB {
  align-items: flex-start;
  color: Black;
  font-family: 'Work Sans', sans-serif;
  font-size: 45px;
  font-weight: 600;
  text-align: center;
}

.encabezadoBlanco {
  align-items: flex-start;
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 45px;
  font-weight: 600;
  text-align: center;
}

.encabezadoLigero {
  align-items: flex-start;
  color: $oxfordDark;
  font-family: 'Work Sans', sans-serif;
  font-size: 45px;
  font-weight: 200;
  text-align: center;
}

.encabezadoLigeroDB {
  align-items: flex-start;
  color: Black;
  font-family: 'Work Sans', sans-serif;
  font-size: 45px;
  font-weight: 200;
  text-align: center;
}

.estatus {
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.ex1 {
  height: 210px;
  overflow: auto;
}

.ex2 {
  flex: 1;
  overflow: auto;
}

.f {
  text-align: center;
  width: 90%;
}

.footer4 {
  background-color: $fourthColor;
  width: 100%;
}

.funciones {
  border: 1px solid $mainColor;
  border-radius: 6px;
  line-height: 1;
  margin: 0 !important;
  padding: 25px 15px !important;
}

.grado {
  align-items: flex-start;
  color: $thirdColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.h {
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
}

.hrs, .txt {
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  text-align: center;
}

.i {
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  line-height: 1.2;
  word-break: keep-all;
  margin: 0;
}

.icon {
  width: 100px;
}

.iconoFooter {
  padding: 25px;
}

.imagenLanding {
  background-size: 100vw 100vh;
  height: 100vh;
  width: 100vw;
}

.imagenh {
  height: 45px;
  width: 45px;
}

.imagenl {
  width: 50%;
  margin: 0 auto;
}

.imagen2 {
  width: 70%;
  margin: 0 auto;
}

.imagenLMovil {
  width: 80%;
  margin: 0 auto;
}

.imagenmenu {
  height: 250px;
  width: 250px;
  margin: 0 auto;
}

.img {
  height: 2vh;
  margin-left: 390px;
  width: 2vh;
}

.labelform {
  text-align: center;
}

.ll {
  box-sizing: border-box;
  color: $fifthColor;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.logoContainer {
  align-items: center;
  background-color: $fourthColor;
  border-radius: 6px;
  display: flex;
  height: 20vh;
  justify-content: center;
  width: 25vw;
  z-index: 1;
}

.logolanding {
  height: 71%;
  width: 72%;
}

.mainMenucontainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main_title {
  align-items: flex-start;
  color: $thirdColor;
  font-family: 'SF-bold', sans-serif;
  font-size: 16px;
  text-align: left;
}

.manuItems {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 10vh;
  justify-content: center;
  width: 30vw;
}

.materias {
  height: 20px;
  width: 210px;
}

.mdl {
  height: 200px;
  width: 450px;
}

.menu, .nav {
  background-color: $fourthColor;
}

.menuContainer {
  display: flex;
  flex-direction: row;
}

.menuItem {
  margin: 0 30px;
}

.modal {
  height: 300px;
  width: 550px;
}

.modalWidth {
  width: 25vw;
}

.modalWidth1 {
  width: 45vw;
}

.mul {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 9px;
  font-weight: 400;
  text-align: center;
  width: 26%;
}

.myVideo {
  min-height: 115vh;
  min-width: 100vw;
  position: relative;
}

.navbar {
  margin-bottom: -100px;
}

.o {
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
}

.p {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  text-align: justify;
}

.panel {
  width: 100%;
}

.panel2 {
  width: 70%;
}

.por {
  border-radius: 15px;
}

.pp {
  font-family: 'Work Sans', sans-serif;
  font-weight: 800;
  text-align: justify;
}

.r {
  width: 400px;
}

.rr {
  width: 90%;
}

.scr {
  height: 450px;
  width: 450px;
}

.scroll {
  float: left;
  overflow: auto;
  border-color: white;
}

.scroll2 {
  float: left;
  overflow: auto;
  width: 90%;
  border-color: gray;
}

.scrollView {
  overflow: auto;
}

.separadorTop {
  border-right: 1px inset;
  color: $fifthColor;
  padding-left: 15px;
  padding-right: 15px;
}

.storeLogos {
  align-items: center;
  justify-content: center;
  width: 277px;
}

.storeLogosRow {
  text-align: center;
  width: 100%;
}

.sub {
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
}

.subnegritas {
  align-items: flex-start;
  color: $thirdColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.25vmax;
  font-weight: 600;
  text-align: center;
}

.negritas {
  align-items: center;
  color: $thirdColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 1vw;
  font-weight: 600;
  text-align: center;
}

.textCaja {
  align-items: center;
  color: $thirdColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 2vw;
  font-weight: 600;
  text-align: center;
}

.subtitulo {
  align-items: flex-start;
  color: $oxfordDark;
  font-family: 'Work Sans', sans-serif;
  font-size: 23px;
  font-weight: 400;
  text-align: center;
}

.subtituloDB {
  align-items: flex-start;
  color: Black;
  font-family: 'Work Sans', sans-serif;
  font-size: 23px;
  font-weight: 400;
  text-align: center;
}

.subtitulo2 {
  align-items: flex-start;
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
}

.subtitulo2Blanco {
  align-items: flex-start;
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
}

.switchMargin {
  margin-right: 5px;
}

.t {
  width: 95%;
}

.t1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
  font-weight: 800;
}

.t2 {
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  font-weight: 200;
}

.t3 {
  font-family: 'Work Sans', sans-serif;
  font-size: 11px;
  font-weight: 400;
}

.t4 {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.txt10 {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 200;
}

.txt4 {
  color: red;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.txt9 {
  font-family: 'Work Sans', sans-serif;
  font-size: 9.5px;
  font-weight: 200;
}

.txtlist {
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
}

.txtVideo {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: $fourthColor;
  position: absolute;
  width: 100%;
  padding: 20px;
}

.txtacor2 {
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.txtheader {
  align-items: flex-start;
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.txtfooter {
  align-items: flex-start;
  color: $grayColorTwo;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.txtnegritas {
  align-items: center;
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.txtnegritasMini {
  align-items: center;
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.txtnegritasMiniBlack {
  align-items: center;
  color: Black;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.txtnegritasmicroMini {
  align-items: center;
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

.txtt {
  color: red;
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}

.videoContainer {
  background-color: $fourthColor;
  min-width: 100%;
  position: relative;
}

.work {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}

.workInput {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.work1 {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
}

.work2, .work3 {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 11px;
  text-align: center;
  word-break: keep-all;
}

.work4 {
  color: $fifthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 8px;
  text-align: center;
  word-break: keep-all;
}

.workBlanco {
  color: $fourthColor;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  text-align: center;
}

.xk {
  height: 620px;
  width: 800px;
}

.y {
  text-align: center;
  margin: 0 auto;
}

.z {
  display: flex;
  justify-content: center;
}

.menuButton {
  background-color: $grayColorOne;
  color: $fifthColor;
  width: 11%;
  height: 5.5vMax;
  border-radius: 14px;
  line-height: 1;
  padding: .8vh;
  border-width: 0;
}

.menuButton1 {
  background-color: $secondColor;
  color: $fourthColor;
  width: 11%;
  height: 5.5vMax;
  border-radius: 14px;
  line-height: 1;
  padding: .8vh;
  border-width: 0;
}

.separatorBord {
  border-right: 1px inset;
  border-bottom: 0;
}

.columnaDerecha {
  padding-left: 15px !important
}

.columnaIzquierda {
  padding-right: 15px !important
}

.secondaryButtonWidth {
  width: 8%
}

.principalButtonWidth {
  width: 50%
}

@media only screen and (max-height: 750px) {
  .heightCardAsp {
    height: 53vh;
  }
  .heightPreinscrip {
    height: 87.5vh;
  }
}

@media only screen and(max-width: 2000px) {
  .separatorBord {
    border-right: 1px inset;
    border-bottom: 0;
  }
  .columnaDerecha {
    padding-left: 15px !important
  }
  .columnaIzquierda {
    padding-right: 15px !important
  }
  .dayView {
    width: 3.1vw !important;
  }
  .customalertWidth {
    width: 45vw;
    padding: 40px 35px
  }
  .circleDay {
    width: 1.5vw !important;
  }
  .modalWidth1 {
    width: 40vw;
  }
}

@media only screen and (max-width: 1400px) {
  .imagenLanding {
    background-size: 98.75vw 1080px;
    height: 1080px;
    width: 98.75vw;
  }
  .dayView {
    width: 3.9vw !important;
  }
  .circleDay {
    width: 2vw !important;
  }
  .modalWidth {
    width: 30vw;
  }
  .customalertWidth {
    width: 60vw;
    padding: 40px 30px
  }
  .modalWidth1 {
    width: 55vw;
  }
  .x_small-modal {
    width: 35%;
  }
}

@media only screen and (max-width: 1200px) {
  .imagenLanding {
    background-size: 98.65vw 1050px;
    height: 1050px;
    width: 98.65vw;
  }
  .dayView {
    width: 5vw !important;
  }
  .circleDay {
    width: 2vw !important;
  }
  .negritas {
    font-size: 1.5vw;
  }
  .textCaja {
    font-size: 3vw;
  }
  .customalertWidth {
    width: 70vw;
    padding: 40px 30px
  }
  .secondaryButtonWidth {
    width: 9%
  }
}

@media only screen and (max-width: 1000px) {
  .imagenLanding {
    background-size: 98vw 850px;
    height: 850px;
    width: 98vw;
  }
  .dayView {
    width: 5vw !important;
  }
  .circleDay {
    width: 2.5vw !important;
  }
  .modalWidth {
    width: 40vw;
  }

  .modalWidth1 {
    width: 80vw;
  }
  .customalertWidth {
    width: 70vw;
    padding: 40px 25px
  }
  .subnegritas {
    font-size: 1.5vmax;
  }

  .secondaryButtonWidth {
    width: 9.5%
  }
}

@media only screen and (max-width: 839px) {
  .separator {
    height: 150px
  }
  .separator-small {
    height: 75px
  }
  .separatorBord {
    border-bottom: 1px inset;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    border-right: 0;
  }
  .columnaDerecha {
    padding-left: 0 !important;
    margin-top: 10px !important;
  }
  .customalertWidth {
    width: 75vw;
    padding: 40px 25px
  }
  .columnaIzquierda {
    padding-right: 0 !important;
    margin-bottom: 10px !important;
  }
  .secondaryButtonWidth {
    width: 10%
  }
  .xx_small-modal {
    width: 38%;
  }
}

@media only screen and (max-width: 800px) {
  .separator {
    height: 120px
  }
  .separator-small {
    height: 65px
  }
  .imagenLanding {
    background-size: 97.5vw 650px;
    height: 650px;
    width: 97.5vw;
  }
  .dayView {
    width: 10vw !important;
  }
  .circleDay {
    width: 3vw !important;
  }
  .modalWidth {
    width: 50vw;
  }
  .modalWidth1 {
    width: 80vw;
  }
  .negritas {
    font-size: 1.7vw;
  }
  .textCaja {
    font-size: 3.4vw;
  }
  .modalAdmisionWidth {
    width: 60vw;
  }
  .widthDoctos {
    width: 80vw;
  }
  .customalertWidth {
    width: 80vw;
    padding: 40px 20px
  }
  .widthCardDoc {
    width: 50%;
  }
  .subtitulo {
    font-size: 18px;
  }

  .secondaryButtonWidth {
    width: 12%
  }
}

@media only screen and (max-width: 750px) {
  .encabezado {
    align-items: flex-start;
    color: $oxfordDark;
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }

  .encabezadoDB {
    align-items: flex-start;
    color: Black;
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }

  .encabezadoBlanco {
    align-items: flex-start;
    color: $fourthColor;
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }

  .encabezadoLigero {
    align-items: flex-start;
    color: $oxfordDark;
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: 200;
    text-align: center;
  }

  .encabezadoLigeroDB {
    align-items: flex-start;
    color: Black;
    font-family: 'Work Sans', sans-serif;
    font-size: 35px;
    font-weight: 200;
    text-align: center;
  }
  .separator {
    height: 100px
  }
  .separator-small {
    height: 60px
  }
  .widthCardDoc {
    width: 100%;
  }
  .subnegritas {
    font-size: 2vmax;
  }
  .small-modal {
    width: 60%;
  }

  .x_small-modal {
    width: 55%;
  }

  .customalertWidth {
    width: 93vw;
    padding: 40px 10px
  }
  .medium-modal {
    width: 80%;
  }
  .customalertFont {
    font-size: 0.75rem
  }

  .secondaryButtonWidth {
    width: 15%
  }
}

@media only screen and (max-width: 600px) {
  .separator {
    height: 90px
  }
  .separator-small {
    height: 50px;
  }
  .imagenLanding {
    background-size: 97vw 450px;
    height: 450px;
    width: 97vw;
  }
  .encabezado {
    font-size: 30px;
  }
  .calltotext {
    font-size: 30px;
  }
  .work {
    font-size: 10px;
  }
  .funciones {
    width: 115px;
  }
  .modalWidth {
    width: 80vw;
  }
  .widthDoctos {
    width: 90vw;
  }
  .modalWidth1 {
    width: 90vw;
  }
  .negritas {
    font-size: 5vw;
  }
  .textCaja {
    font-size: 5vw;
  }
  .modalAdmisionWidth {
    width: 65vw;
  }
  .widthCardDoc {
    width: 100%;
  }
  .small-modal {
    width: 65%;
  }
  .x_small-modal {
    width: 60%;
  }
  .customalertWidth {
    width: 94vw;
    padding: 40px 5px;
  }
  .customalertFont {
    font-size: 0.75rem
  }
  .medium-modal {
    width: 85%;
  }
  .secondaryButtonWidth {
    width: 18%
  }
}

@media only screen and (max-width: 550px) {
  .modalAdmisionWidth {
    width: 55vw;
  }
  .customalertWidth {
    width: 95vw;
    padding: 40px 5px
  }
  .customalertFont {
    font-size: 0.75rem
  }
  .subtitulo {
    font-size: 15px;
  }
  .secondaryButtonWidth {
    width: 20%
  }
}

@media only screen and (max-width: 500px) {
  .modalAdmisionWidth {
    width: 55vw;
  }
  .customalertWidth {
    width: 100vw;
    padding: 40px 5px;
    font-size: 1em
  }
  .customalertFont {
    font-size: 0.75rem
  }
  .own-modal-content {
    width: 85%;
  }
  .small-modal {
    width: 85%;
  }
  .x_small-modal {
    width: 85%;
  }
  .secondaryButtonWidth {
    width: 30%
  }
}

@media only screen and (max-width: 485px) {
  .titAcord {
    width: 80%;
  }
  .imagenLanding {
    background-size: 100vw 100vh;
    height: 100vh;
    width: 100vw;
  }
  .cuadroLogin {
    align-items: center;
    justify-content: center;
    max-width: 30vw;
    max-height: 40vh;
  }
  .modalAdmisionWidth {
    width: 75vw;
  }
  .secondaryButtonWidth {
    width: 50%
  }
}

@media only screen and (max-width: 400px) {
  .subtitulo {
    font-size: 13px;
  }
  .cuadroLogin {
    align-items: center;
    justify-content: center;
    max-width: 30vw;
    max-height: 40vh;
  }
  .modalAdmisionWidth {
    width: 40vw;
  }
  .cuadroDocto {
    height: 16.5vh;
  }
  .widthDoctos {
    width: 75vw;
  }
}

@media only screen and (max-width: 350px) {
  .separadorTop {
    padding-right: 2px;
    padding-left: 2px;
  }
}

@media only screen and (min-width: 1475px) {
  .cardlanding {
    height: 100vh;
  }
  .xx_small-modal {
    width: 30%;
  }
}

@import url(https://fonts.googleapis.com/css?family=Lato:700);

.wrapper-card {
  display: flex;
  flex-wrap: nowrap;
  margin: 40px auto;
  width: 100%;
}

.card {
  color: $fourthColor;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  flex: 1;
  margin: 8px;
  padding: 30px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease-in-out;

  &.popular {
    margin-top: -10px;
    margin-bottom: -10px;

    .card-title {
      h3 {
        color: $secondColor;
        font-size: 22px;
      }
    }

    .card-price {
      margin: 0;

      h1 {
        color: $secondColor;
        font-size: 60px;
      }
    }

    .card-action {
      text-align: center;

      button {
        background-color: $secondColor;
        border-radius: 80px;
        color: $fourthColor;
        font-size: 12px;
        margin-top: -15px;
        padding: 10px;
        height: 40px;

        &:hover {
          background-color: darken($secondColor, 7);
          font-size: 14px;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
}

.card-ribbon {
  position: absolute;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: 114px;
  height: 112px;

  span {
    position: absolute;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: $secondColor;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: $fourthColor;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid $secondColor;
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &::before {
    top: 0;
    right: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }
}

.card-title {
  h3 {
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    text-transform: uppercase;
  }

  h4 {
    color: rgba(0, 0, 0, 0.6);
  }
}

.card-price {
  margin: 0 0;

  h1 {
    font-size: 46px;

    sup {
      font-size: 15px;
      display: inline-block;
      margin-left: -20px;
      width: 10px;
    }

    small {
      color: rgba(0, 0, 0, 0.3);
      display: block;
      font-size: 11px;
      text-transform: uppercase;
    }
  }
}

.card-description {
  ul {
    display: block;
    list-style: none;
    margin: 10px 0;
    padding: 0;
  }

  li {
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    margin: 0 0 15px;

    &::before {
      font-family: FontAwesome, fantasy;
      content: "✓";
      padding: 0 5px 0 0;
      color: $secondColor;
    }
  }
}

.alertPulse-css {
  animation: opacityPulse .5s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

.alertPulse-css-1 {
  animation: opacityPulse 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

.alertPulse-css-2 {
  animation: opacityPulse 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

#btnPulse:active {
  animation: opacityPulse 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes opacityPulse {
  0% {
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.0;
  }
}

//.hover-background:hover .hover-thumb {
//    -webkit-transform: rotate(-15deg);
//    -moz-transform: rotate(-15deg);
//    -ms-transform: rotate(-15deg);
//    -o-transform: rotate(-15deg);
//    //font-size: 16px;
//
//}

.hover-background:hover {
  background-color: #f1f1f1;
}

.animate {
  animation: wobble .5s 1;
}

@keyframes wobble {
  10% {
    transform: scale(1.1) rotate(-3deg);
  }
  20% {
    transform: scale(1.2) rotate(-6deg);
  }
  30% {
    transform: scale(1.3) rotate(-9deg);
  }
  40% {
    transform: scale(1.4) rotate(-13deg);
  }
  50% {
    transform: scale(1.5) rotate(-15deg);
  }
  60% {
    transform: scale(1.4) rotate(-13deg);
  }
  70% {
    transform: scale(1.3) rotate(-9deg);
  }
  80% {
    transform: scale(1.2) rotate(-6deg);
  }
  90% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}


.card-action {
  text-align: center;

  button {
    width: 80%;
    background-color: $secondColor;
    border-radius: 80px;
    color: $fourthColor;
    font-size: 12px;
    margin-top: -15px;
    padding: 10px;
    height: 40px;

    &:hover {
      background-color: darken($secondColor, 7);
      font-size: 14px;
    }
  }
}

.chat-view {
  height: calc(90vh - 95px)
}

.uk-width-1-12 {
  width: calc(100% * 1 / 12.001)
}

.uk-width-1-10 {
  width: calc(100% * 1 / 10.001)
}

.uk-width-1-8 {
  width: calc(100% * 1 / 8.001)
}

.uk-width-7-8 {
  width: calc(100% * 7 / 8.001)
}

.uk-width-1-16 {
  width: calc(100% * 1 / 16.001)
}

.uk-width-15-16 {
  width: calc(100% * 15 / 16.001)
}

.uk-width-1-20 {
  width: calc(100% * 1 / 20.001)
}

.uk-width-7-20 {
  width: calc(100% * 7 / 20.001)
}

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-radius: 6px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #ccc;
}


/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  padding: 5px;
}

.blob {
  border-radius: 50%;
  margin: 0;
  height: 15px;
  width: 15px;

  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.65);
    box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.7);
  }

  70% {
    transform: scale(0.7);
    box-shadow: 0 0 0 10px rgba(150, 150, 150, 0);
  }

  100% {
    transform: scale(0.65);
    box-shadow: 0 0 0 0 rgba(150, 150, 150, 0);
  }
}

.btn-menu-selected {
  height: 30px;
  background-color: $oxfordDark;
  border-radius: 0 15px 15px 0;
  align-items: center;
  justify-content: center;
  transition: background 1s;
}

.btn-menu {
  background: $fourthColor;
  height: 30px;
  align-items: center;
  justify-content: center;
  transition: background 1s;
}

.btn0-menu-selected {
  background: $fourthColor;
  transition: background 1s;
}

.btn0-menu {
  height: 30px;
  background-color: $oxfordDark;
  transition: background 1s;
}

.btn-menu-selected-top {
  height: 30px;
  border-radius: 0 0 0 15px;
  transition: border-radius 1s;
}

.btn-menu-selected-bottom {
  border-radius: 15px 0 0 0;
  transition: border-radius 1s;
}

/*both menus styles*/
.btn-both-selected-top {
  height: 30px;
  border-radius: 0 0 15px 15px;
  transition: border-radius 1s;
}

/*sub menus styles*/
.btn-sub-menu {
  padding: 0 10px !important;
  background-color: $fourthColor;
  height: 30px;
  align-items: center;
  justify-content: center;
  transition: border-radius 1s;
}

.btn-sub-menu.btn-sub-menu-selected {
  padding: 0 10px !important;
  height: 30px;
  background-color: $oxfordDark;
  border-radius: 15px 0 0 15px;
  align-items: center;
  justify-content: center;
  transition: border-radius 1s;
}

.btn0-sub-menu {
  height: 30px;
  background-color: $oxfordDark;
}

.btn0-sub-menu.btn0-sub-menu-selected {
  height: 30px;
  background-color: $fourthColor;
  transition: border-radius 1s;
}

.btn-sub-menu-selected-top {
  height: 30px;
  border-radius: 0 0 15px 0;
  transition: border-radius 1s;
}

.btn-sub-menu-selected-bottom {
  border-radius: 0 15px 0 0;
  transition: border-radius 1s;
}

.text-sub-menu {
  text-align: left;
  color: $oxfordDark;
  transition: color 1s;
}


.text-sub-menu-selected {
  text-align: left;
  color: $fourthColor;
  transition: color 1s;
}

.logo_box {
  padding: 10px !important;
  border-radius: 15px;
  background: transparent;
}

.betaMenuHeight {
  max-height: calc(100vh - 210px);
}

.betaMenuHeight2 {
  max-height: calc(100vh - 210px);
}

div.grid {
  margin: 0;
  padding: 0;
}

.chat-view-class {
  height: calc(100% - 45px - 8%)
}

.hover-background-gray:hover {
  background-color: #CCCCD0;
  cursor: pointer;
}

.hover-border-second:hover {
  border: 1px solid;
  border-color: $grayColorThree !important;
}

.hover-border-second {
  background-color: #f1f1f1;
  border-color: #f1f1f1 !important;
  border: 1px solid;
  color: $fifthColor ;
  cursor: pointer;
}


.hover-background-second:hover {
  background-color: $secondColor;
  cursor: pointer;
}

.hover-background-second {
  background-color: #f1f1f1;
  cursor: pointer;
}

.hover-text-second:hover {
  color: $fourthColor;
}

.hover-text-second {
  color: $fifthColor ;
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    //margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.file_container {
  border-radius: 6px;
  background-color: $fourthColor;
  cursor: pointer;
}

.file_container:hover {
  background-color:$grayColorOne;
  cursor: pointer;
}

.main-container {
  padding: 0 5px !important;
}


.container-context {
  width: 100%;
  border-radius: 6px;
  height: 600px;
  background-color: #e0e0e0;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
}

.context-menu {
  position: fixed;
  border: 1px solid black;
  background-color: white
}

.context-menu .context-menu__item {
  padding: 4px;
}

.context-menu .context-menu__item:not(:first-child) {
  border-top: 1px solid black;
}

.context-menu .context-menu__item:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.menu-down {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 133px;
  right: 14%;
  z-index: 1;
  width: 209px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.menu-down.active {
  opacity: 1;
  visibility: visible;
}

.dowm-item {
  width: 200px;
  padding: 4px;
  cursor: pointer;
}

.dowm-item:not(:first-child) {
  border-top: 1px solid black;
  cursor: pointer;
}

.dowm-item:hover {
  background-color: lightgrey;
  cursor: pointer;
  cursor: pointer;
}
